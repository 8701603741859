<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Regulamentações e Carências</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-row
      justify="center"
      no-gutters
      class="pa-2 mt-6"
    >
      <v-col
        lg="8"
        md="12"
        sm="12"
        cols="12"
      >
        <v-form ref="form">
          <v-row>
            <v-col
              lg="2"
              md="2"
              sm="12"
              cols="12"
            >
              <v-checkbox
                v-model="regulation.status"
                label="Ativo?"
                :true-value="1"
                :false-value="0"
              />
            </v-col>
            <v-col
              class="d-block"
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <v-text-field
                v-model="regulation.title"
                :counter="200"
                outlined
                dense
                label="Título da Campanha *"
                :rules="$rules.required"
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      no-gutters
      class="pa-2 mt-6"
    >
      <v-col
        lg="8"
        md="12"
        sm="12"
        cols="12"
        class="d-flex justify-space-between"
      >
        <v-file-input
          v-model="attcUpload"
          label="Clique para adicionar novos arquivos"
          outlined
          show-size
          counter
          accept="application/pdf"
          :rules="rules"
          @change="checkFileUpload"
        />
        <v-btn
          v-if="regulation.link"
          class="d-flex align-self-center ma-5"
          color="primary"
          @click="downloadFile"
        >
          Baixar PDF
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import RegulationService from '../../../services/regulation.service';
import { image } from '../../../services/upload-service';

export default {
  name: 'RegulationForm',

  props: {
    populateWith: {
      type: Object,
      default: () => ({
        id: null,
        status: 0,
        title: '',
        createdAutor: '',
        updatedAutor: '',
        createdAt: '',
        updatedAt: '',
        link: '',
      }),
    },
  },

  data() {
    return {
      rules: [
        (v) => !!v || 'Um Arquivo é obrigatório',
        (v) => (v && v.size > 0) || 'Um Arquivo é obrigatório',
        (v) => !v || v.size < 1000000 || 'Arquivo deve ter no máximo 1MB!',
      ],
      regulation: {
        title: '',
        link: '',
        status: 0,
        createdAutor: '',
        updatedAutor: '',
        createdAt: '',
        updatedAt: '',
      },
      attcUpload: [],
      isLoading: false,
    };
  },

  async created() {
    this.initializeDataFromPopulateWith();
  },

  methods: {
    close() {
      this.$emit('close-dialog');
    },

    initializeDataFromPopulateWith() {
      if (this.populateWith.id) {
        this.regulation = { ...this.populateWith };
      }
    },

    downloadFile() {
      if (this.regulation.link) {
        const link = document.createElement('a');
        link.href = this.regulation.link;
        link.target = '_blank';
        link.download = `${this.regulation.title}.pdf`;
        link.click();
      }
    },

    async checkFileUpload(file) {
      try {
        this.isLoading = true;
        if (file) {
          const [response] = await image(file);
          if (response) {
            this.regulation.link = response;
          } else {
            throw new Error('A resposta da service não contém a URL esperada.');
          }
        } else {
          console.error('Nenhum arquivo PDF selecionado');
        }
      } catch (error) {
        console.error('Erro ao fazer upload do arquivo PDF', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async updateRegulation() {
      try {
        this.isLoading = true;
        await RegulationService.updateById(this.regulation.id, this.regulation);
        this.$toast.success('Regulamento editado com sucesso');
        this.isLoading = false;
        this.$emit('update-list');
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.isLoading = false;
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      if (this.populateWith.id) {
        this.regulation.updatedAt = new Date().toISOString();
        await this.updateRegulation();
      } else {
        try {
          this.isLoading = true;
          this.regulation.createdAt = new Date().toISOString();
          this.regulation.updatedAt = new Date().toISOString();

          await RegulationService.create({ ...this.regulation });

          this.clearForm();
          this.$toast.success('Regulamento cadastrado com sucesso');
          this.$emit('update-list');
          this.isLoading = false;
          this.close();
        } catch (e) {
          this.$handleHttpError(e);
          this.isLoading = false;
        }
      }
    },

    clearForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.attcUpload = [];
    },
  },
};
</script>
